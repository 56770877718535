
import { mapMutations, mapState } from 'vuex'
import { BIconX } from 'bootstrap-vue'
import InfiniteLoading from 'vue-infinite-loading'
import NeedHelpPopup from '~/components/UI/need-help-popup.vue'
// import {params} from 'vee-validate/dist/types/rules/alpha';
// import ModalProductPreview from '~/components/modal/product_preview.vue'
export default {
  components: {
    BIconX,
    NeedHelpPopup,
    InfiniteLoading
    // ModalProductPreview
  },
  async asyncData ({ params, $axios, $replaceSEO }) {
    let seo
    if (params.category) {
      seo = await $axios.get('sitemaps/samples_category')
    } else {
      seo = await $axios.get('sitemaps/samples')
    }
    let sampleProductCategories = []
    // let sampleProducts = []

    sampleProductCategories = await $axios.get('sample_product_categories')
    sampleProductCategories = sampleProductCategories.data
    const tmpSampleProductCategories = {}
    for (const k in sampleProductCategories) {
      tmpSampleProductCategories[sampleProductCategories[k].id] = sampleProductCategories[k]
    }

    /* sampleProducts = await $axios.get('sample_product_category/' + params.category + '/sample_products')
    sampleProducts = sampleProducts.data.data */

    const item = sampleProductCategories.filter(x => x.id === parseInt(params.category))
    const catName = (parseInt(params.category) === 0) ? 'ทั้งหมด' : item[0].name
    return {
      sampleProductCategories: tmpSampleProductCategories,
      sampleProductCategoryCount: sampleProductCategories.length,
      // sampleProducts,
      selectedCategoryId: params.category,
      name: catName,
      seo: $replaceSEO(seo.data.data, null, params.category, null, catName, null)
    }
  },
  data () {
    return {
      categoryMenuHeight: '72px',
      sampleProductCategories: [],
      sampleProducts: [],
      selectedCategoryId: 0,
      waypointLoaded: [],
      maxImpression: 6,
      slide: 0,
      sliding: null,
      loading: false,
      modalProductDetial: {
        name: '',
        description: '',
        imageSlide: [],
        relatedProducts: []
      },
      headTitle: 'รวมงาน ออกแบบ บรรจุภัณฑ์ กล่องกระดาษ สติกเกอร์ ทำกล่อง สวยๆ',
      headDes: 'ให้บริการออกแบบ และผลิตบรรจุภัณฑ์ โดยโรงงานกล่องกระดาษ กล่องไปรษณีย์ กล่องสวยๆ กล่องครีม สติกเกอร์ติดกล่อง ครบจบในที่เดียว สะดวกเช็คราคา สั่งซื้อ ออนไลน์24hr.',
      headKey: 'ผลิตกล่อง,กล่องกระดาษ,กล่องไปรษณีย์,กล่องลูกฟูก,กล่องสวยๆ,กล่องครีม,สติกเกอร์,สติกเกอร์ติดกล่องอาหาร,ออกแบบบรรจุภัณฑ์,โรงงานผลิตกล่อง,บรรจุภัณฑ์,กล่องส่งพัสดุ,กล่องอาหาร,หลอดครีม,ขวดพลาสติก,ถุงพลาสติก,พลาสติก,กล่องสวยๆ,ส่งเดลิเวอรี่,การส่งพัสดุ,การขายออนไลน์',
      page: 1
    }
  },
  computed: {
    ...mapState(['userData'])
  },
  mounted () {
    this.waypointLoaded = []
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: 'category',
        path: `${this.$route.path}`,
        title: this.seo.title
      },
      user: {
        customerID: (this.userData) ? this.userData.uid : '',
        customerEmail: (this.userData) ? this.userData.email : ''
      }
    })
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'setAlertWithLine']),
    showCategoryMenu () {
      this.categoryMenuHeight = this.categoryMenuHeight === '100%' ? '72px' : '100%'
    },
    productClicked (pattern, indexPT) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'productClicked',
        ecommerce: {
          click: {
            actionField: { list: 'ผลงานที่ผ่านมา' },
            products: [{
              id: `LOCOPACK_SAMPLE_${pattern.sample_product_category_id}` + pattern.id.toString().padStart(9, '0'),
              name: pattern.name,
              price: pattern.price_per_pcs,
              category: this.sampleProductCategories[pattern.sample_product_category_id].name,
              brand: 'Locopack',
              position: indexPT + 1,
              dimension1: '',
              dimension2: '',
              dimension3: '',
              dimension4: '',
              dimension5: '',
              dimension6: '',
              dimension7: '',
              dimension8: '',
              dimension9: '',
              dimension10: '',
              dimension11: '',
              dimension12: '',
              dimension13: ''
            }]
          }
        }
      })
    },
    async loadSampleProducts ($state) {
      try {
        await this.$axios.get('v2/sample-products', {
          params: {
            page: this.page,
            category_id: this.selectedCategoryId
          }
        }).then((res) => {
          const data = res.data.data
          if (data.data.length) {
            this.sampleProducts.push(...data.data)
            if (this.page >= data.last_page) {
              $state.complete()
            } else {
              $state.loaded()
            }
            this.page++
          } else {
            $state.complete()
          }
        })
      } catch (error) {
        console.error('Error loading items:', error)
        $state.error()
      }
    }
  },
  head () {
    return {
      title: this.seo.title,
      meta: [
        { hid: 'description', name: 'description', content: this.seo.description },
        { hid: 'keywords', name: 'keywords', content: this.seo.keywords }
      ],
      link: [
        {
          rel: 'canonical',
          href: this.seo.canonical_url
        }
      ]
    }
  }
}
